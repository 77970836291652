@font-face {
	font-family: "Book Antiqua";
	src: url("./fonts/book-antiqua.eot"); /* IE9 Compat Modes */
	src: url("./fonts/book-antiqua.eot?#iefix") format("embedded-opentype"), /* IE6-IE8 */
		url("./fonts/book-antiqua.otf") format("opentype"), /* Open Type Font */
		url("./fonts/book-antiqua.svg") format("svg"), /* Legacy iOS */
		url("./fonts/book-antiqua.ttf") format("truetype"), /* Safari, Android, iOS */
		url("./fonts/book-antiqua.woff") format("woff"), /* Modern Browsers */
		url("./fonts/book-antiqua.woff2") format("woff2"); /* Modern Browsers */
	font-weight: normal;
	font-style: normal;
}