.two {
    transition: 1s;
   }
   .two:hover {
        transform: scale(1.05);
   }
.link {
    font-weight: bold;
    color: black;
    text-decoration: none;
    font-size: 18px;
}

.link:hover {
    color: #1200ba;
    font-weight: bold;
}

.link:focus {
    color: #1200ba;
    font-weight: bold;
}

.link:active {
    color: #1200ba;
    font-weight: bold;
}

.hide-on-mobile { display: inline; }
@media only screen
    and (min-device-width : 320px)
    and (max-device-width : 950px){ .hide-on-mobile { display: none; }}

.show-on-mobile { display: none; }
@media only screen
    and (min-device-width : 320px)
    and (max-device-width : 950px){ .show-on-mobile { display: inline; }}